import VaporInstance from 'laravel-vapor';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('courses', ['courseOnSale']),
    // Get domain
    domain() {
      return window.data.domainSlug;
    },
    // Get current language
    currentLanguage() {
      const isCrowdin = window.location.href.includes('crowdin-editor=1') || window.App.appEnvironment === 'l10n';

      if (isCrowdin) {
        return localStorage.getItem('jipt_language_code_course-portal').substring(0, 2);
      }

      return window.App.language;
    },
    // Check if current language is English
    isEnglish() {
      return window.App.language === 'en';
    },
    isAppMode: () => {
      return !!(
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers['iap-products-request']
      );
    },
    // Check if device is phone
    isPhone() {
      return this.isMobile && !this.isIPad;
    },
    // Check if device is phone or tablet
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream)
      ) {
        return true;
      }
      return false;
    },
    // Check if device is iPad
    isIPad() {
      if (
        /iPad/i.test(navigator.userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream)
      ) {
        return true;
      }
    },
    // Check if device is iPhone
    isIPhone() {
      if (
        /iPhone/i.test(navigator.userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream)
      ) {
        return true;
      }
    },
    // Check if browser is Chrome
    isChrome() {
      return (
        /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) && !/Edg/.test(navigator.userAgent)
      );
    },
    // Check if browser is Safari
    isSafari() {
      return navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1;
    },
    // Check if local storage supported
    isLocalStorageSupported() {
      return 'localStorage' in window;
    },
    // Check if cookieStore is supported
    isCookieStoreSupported() {
      return 'cookieStore' in window;
    },
    getStorageMethods() {
      if (this.isCookieStoreSupported) {
        return {
          get: cookieStore.get.bind(cookieStore),
          set: cookieStore.set.bind(cookieStore),
        };
      } else if (this.isLocalStorageSupported) {
        return {
          get: localStorage.getItem.bind(localStorage),
          set: localStorage.setItem.bind(localStorage),
        };
      }
      return null;
    },
    // Get device pixel ratio
    pixelRatio() {
      return window.devicePixelRatio;
    },
  },
  methods: {
    // Fire tracking event
    trackEvent(action, data_event) {
      window.ipse.dispatch({
        action: action,
        ...(data_event && { data_event: data_event }),
      });
    },
    // Gets assets path
    asset(path) {
      const basePath = window._asset;
      return basePath ? `${basePath}${path}` : path;
    },
    vaporAsset(path) {
      VaporInstance.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);
      return VaporInstance.asset(path);
    },
    // Returns "true" if element is visible in viewport
    elementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    isFullscreen() {
      if (document.fullscreenElement) return document.fullscreenElement;
      else if (document.webkitFullscreenElement) return document.webkitFullscreenElement;
      else if (document.mozFullscreenElement) return document.mozFullscreenElement;
      else if (document.msFullscreenElement) return document.msFullscreenElement;
    },
    closeFullscreen() {
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
    },
    convertSecondsToTime(val) {
      const h = Math.floor(val / 3600);
      const m = Math.floor((val % 3600) / 60);
      const s = Math.round(val % 60);

      return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter(Boolean).join(':');
    },
    convertSimpleDate(val) {
      const date = new Date(val);
      const dateFormatted = `${date.getFullYear().toString().padStart(4, '0')}/${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')} ${date
          .getHours()
          .toString()
          .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
            .getSeconds()
            .toString()
            .padStart(2, '0')}`;
      return dateFormatted;
    },
    // Converts dates into "how long ago" format
    // eg. 3 days ago, 2 years ago etc...
    timeago(ptime) {
      if (!ptime) return '';

      let ctime = Date.now();
      let ntime = new Date(ptime).getTime();
      let seconds = Math.floor((ctime - ntime) / 1000);
      let intervals = [
        Math.floor(seconds / 31536000),
        Math.floor(seconds / 2592000),
        Math.floor(seconds / 604800),
        Math.floor(seconds / 86400),
        Math.floor(seconds / 3600),
        Math.floor(seconds / 60),
      ];
      let times = [
        this.$t('timer.year'),
        this.$t('timer.month'),
        this.$t('timer.week'),
        this.$t('timer.day'),
        this.$t('timer.hour'),
        this.$t('timer.minute'),
      ];
      let timesPlural = [
        this.$t('timer.years'),
        this.$t('timer.months'),
        this.$t('timer.weeks'),
        this.$t('timer.days'),
        this.$t('timer.hours'),
        this.$t('timer.minutes'),
      ];
      let key;
      let res;

      for (key in intervals) {
        if (intervals[key] > 1) {
          res = intervals[key] + ' ' + timesPlural[key] + ' ';
          if (this.currentLanguage !== 'en') {
            return this.$t('timer.ago') + ' ' + res;
          }
          return res + ' ago';
        } else if (intervals[key] === 1) {
          res = intervals[key] + ' ' + times[key] + ' ';
          if (this.currentLanguage !== 'en') {
            return this.$t('timer.ago') + ' ' + res;
          }
          return res + ' ago';
        }
      }

      return this.$t('timer.fewSecondsAgo');
    },
    // Formats a number as eg. 2.5K if a thousand or more
    compactNumberFormatter(num, options = { formatBreakpoint: 1_000, space: false }) {
      const NUMBER_SUFFIXES = ['K', 'M', 'B', 'T'];
      const exp = Math.floor(Math.log(num) / Math.log(1000));
      return Math.abs(num) > options.formatBreakpoint - 1
        ? (num / Math.pow(1000, exp)).toFixed(1) + (options.space ? ' ' : '') + NUMBER_SUFFIXES[exp - 1]
        : num.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
    },
    // Adds hyperlinks to URLs and emails in string
    addLinks(string) {
      const url = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      const email = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;

      string = string.replace(url, '<a href="$1" target="_blank" class="dont-break-out">$1</a>');

      return string.replace(email, '<a href="mailto:$1" class="dont-break-out">$1</a>');
    },
    // Adds non-breakable space before last word
    // to avoid single-word lines
    preventWidows(val) {
      return val.replace(/\s([^\s<]+)\s*$/, '\u00A0$1');
    },
    // Copy text to clipboard
    copyTextToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
    // SWIPE EVENTS
    swipeUp(event, callback) {
      const touchStartY = event.changedTouches[0].screenY;
      const touchEndY = event.changedTouches[0].screenY;

      if (touchEndY < touchStartY) {
        callback();
      }
    },
    // Runs a function after orientation has changed
    afterOrientationChange(callback) {
      window.addEventListener('orientationchange', () => {
        const afterOrientationChange = () => {
          callback();
          window.removeEventListener('resize', afterOrientationChange);
        };
        window.addEventListener('resize', afterOrientationChange);
      });
    },
    // Runs a function after window has resized
    afterResize(callback) {
      window.addEventListener('resize', () => {
        const afterResize = () => {
          callback();
          window.removeEventListener('resize', afterResize);
        };
        window.addEventListener('resize', afterResize);
      });
    },
    // Wait for an element to appear in DOM
    waitForElement(selector) {
      return new Promise((resolve) => {
        if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(() => {
          if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
          }
        });

        observer.observe(document.body, {
          childList: true,
        });
      });
    },
    // Changes "utm_medium" value to web or mobile
    // in URLs depending on the device and
    // adds ios_locked_params to locked courses
    handleSalesPageLinkParams(url, iosParams = null) {
      const utmMedium = /utm_medium=web/g;
      const utmSource = /utm_source=course-portal/g;
      const utmMediumReplacement = `utm_medium=course-portal`;
      const utmSourceReplacement = `utm_source=${this.isAppMode ? 'ios' : this.isMobile ? 'mobile' : 'web'}`;
      const newUtmMediumUrl = url.replace(utmMedium, utmMediumReplacement);
      const newUtmSourceUrl = newUtmMediumUrl.replace(utmSource, utmSourceReplacement);
      const aidParam = `&ipse-aid=${window.ipse_aid}`;

      return `${newUtmSourceUrl}${this.generateIosParams(iosParams)}${aidParam}`;
    },
    // Generates iOS params for locked courses
    generateIosParams(params) {
      return this.isAppMode ? (params ? params : this.courseOnSale?.ios_locked_params) : '';
    },
    // Set cookie
    setCookie(name, value, days) {
      let expires = '';

      if (days) {
        const date = new Date();

        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = ';expires=' + date.toUTCString();
      }

      document.cookie = name + '=' + value + expires + ';path=/';
    },
    // Get cookie
    getCookie(cname) {
      const name = cname + '=';
      let ca = document.cookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
      }
      return '';
    },
    // Delete cookie
    deleteCookie(name) {
      document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    disableZoom() {
      document
        .querySelector("meta[name='viewport']")
        .setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');
    },
    joinProductTitle(title) {
      if (this.currentLanguage === 'de') {
        return `${this.$t('banners.join')} ${title} bei`;
      }
      return `${this.$t('banners.join')} ${this.banner.title}`;
    },
  },
};
